<template>
  <div class="background">
    <div class="card">
      <div class="content" >
        <p>

        </p>
        <ul>
          <li style="text-align:center;">
            <font color="#000000" size="6">
              <b style="">云平台用户协议</b>
            </font>
            <br />
          </li>
          <li style="text-align:left;">
            <font color="#000000" size="3">
              <p style="">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;欢迎您来到朗云工业设计协同创新云平台（以下简称云平台）</p>
            </font>
          </li>
          <li style="text-align:left;">
            <p style="">
              <font size="3" color="#000000">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;您可通过云平台APP、云平台官网（https://www.iloncloud.cn/）登录使用云平台，享受云平台服务，但同时代表您已仔细阅读、充分理解以下条款，如果您对本协议的任何条款表示异议，您可以选择不进入云平台。当您注册成功，无论是进入云平台，还是在云平台上发布任何内容（即「内容」），均意味着您（即「在云平台平台注册的主体，以下简称用户」）完全接受本协议项下的全部条款<b style="">。</b>
              </font>
            </p>
          </li>
          <li style="text-align:left;">
            <p>
              <font color="#000000">
                <b>一、用户的权力和义务</b>
              </font>
            </p>
            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp; 1.</span>参与权
              </font>
            </p>
            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>用户有权根据本协议及云平台发布的相关规则，参与项目中心、供应链管理等及有权享受本网站提供的其他有关资讯及信息服务。
              </font>
            </p>
            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp;&nbsp;</span>2.账号管理权
              </font>
            </p>
            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp; &nbsp;&nbsp;</span>● 用户自行管理和负责注册的账号和密码，用户有权根据需要更改登录账号和密码，但云平台无义务审核是否是您本人登录，任何因您个人原因未保管好自己的账号和密码导致密码泄露，包括不限于：遗忘、密码被他人破解、用户使用的计算机被他人侵入等，只要输入密码和用户在数据中保存的一致，即认为是用户本人使用云平台的服务，并对其安全性及使用账号所作出的一切行为负全部责任。
              </font>
            </p>
            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp; &nbsp;&nbsp;</span>● 此账号不得转让、赠与、继承，除非有法律规定或司法判决、裁定或行政裁定，且征得云平台的书面同意。
              </font>
            </p>
            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp; &nbsp;&nbsp;</span>● 您同意：如发现任何人未经授权使用您的账号和密码，或发生违反保密规定的任何其他情况，您会立即通知云平台，并授权云平台将该账号进行冻结。您理解云平台对您的请求采取行动需要合理时间，且云平台应您请求而采取的行动可能无法避免或阻止损害后果的形成或扩大，除云平台存在法定过错外，云平台不承担任何责任。
              </font>
            </p>
            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp;&nbsp;</span>3.用户行为规范
              </font>
            </p>
            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp; &nbsp;&nbsp;</span>● 用户直接或通过各类方式（如RSS源和站外API引用等）间接使用云平台服务和数据的行为，都将被视作已无条件接受本协议全部内容；若用户对本协议的任何条款存在异议，请立即停止注册程序、停止使用云平台平台。
              </font>
            </p>
            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp; &nbsp;&nbsp;</span>● 用户须对在云平台的注册信息的真实性、合法性、有效性承担全部责任（包括不限于：账号昵称、手机号、头像），用户不得冒充他人（包括不限于头像、昵称）；不得利用他人的名义发布任何信息；不得恶意使用注册帐号导致其他用户误认（包括不限于头像、文字、图片）；否则云平台有权立即停止提供服务，收回其帐号并由用户独自承担由此而产生的一切法律责任。
              </font>
            </p>
            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp; &nbsp;&nbsp;</span>● 用户发表的内容（包括不限于上传作品、图片、文字），云平台默认版权均归用户本人所有或用户本人已经依法获得原始权利人合法授权，并且该内容不会侵犯任何第三方的合法权益，如因此产生的法律纠纷由用户本人负责。
              </font>
            </p>
            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp; &nbsp;&nbsp;</span>● 用户不得利用云平台服务制作、上载、复制、发布、传播或者转载如下内容：
              </font>
            </p>
            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span>① 违反宪法或法律法规规定的；
              </font>
            </p>
            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span>② 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
              </font>
            </p>
            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span>③ 损害国家荣誉和利益的，损害公共利益的；
              </font>
            </p>
            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span>④ 煽动民族仇恨、民族歧视，破坏民族团结的；
              </font>
            </p>
            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span>⑤ 侮辱、滥用英烈形象，否定英烈事迹，美化粉饰侵略战争行为的；
              </font>
            </p>
            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span>⑥ 破坏国家宗教政策，宣扬邪教和封建迷信的；
              </font>
            </p>
            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span>⑦ 散布谣言，扰乱社会秩序，破坏社会稳定的；
              </font>
            </p>
            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span>⑧ 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
              </font>
            </p>
            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span>⑨ 侮辱或者诽谤他人，侵害他人合法权益的；
              </font>
            </p>
            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span>⑩ 含有法律、行政法规禁止的其他内容的信息。
              </font>
            </p>
            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp; &nbsp;&nbsp;</span>● 发表任何言论及任何形式的活动、任务，您须同意：
              </font>
            </p>
            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span>① 参与任务禁止上传不文明图片、分发广告、违反法规及不文明用语和政治敏感类的言论；
              </font>
            </p>
            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span>② 严禁抄袭他人创作，引用他人资料需获得原著作权人授权并注明出处；
              </font>
            </p>
            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</span>③ 如用户在云平台平台发表的创意被举报抄袭，侵犯了第三方的著作权或其他权利，责任由用户本人自行承担，云平台不承担任何法律责任，同时云平台有权要求用户就其侵权行为给云平台造成的实际损失进行赔偿并有权拉入云平台平台黑名单。
              </font>
            </p>
            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp; &nbsp;&nbsp;</span>● 同意接收来自云平台设计平台的信息，包括但不限于活动信息、任务入选等。
              </font>
            </p>
            <p>
              <b>
                <font color="#000000">二、云平台设计平台的权利和义务</font>
              </b>
            </p>
            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp;&nbsp;</span>1. 云平台有义务尽量避免中断服务或中断时间限制在最短的时间内，努力确保平台的正常运行。
              </font>
            </p>
            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp;&nbsp;</span>2. 对用户在注册使用云平台所遇到的与支付或注册有关的问题及反映的情况及时作出回复。
              </font>
            </p>
            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp;&nbsp;</span>3. 云平台有权对用户使用云平台的情况进行审查和监督，如用户在使用平台时违反以下情形的，云平台有权要求用户改正或不经过用户允许直接采取一切必要的措施（包括但不限于更改或删除用户发布的内容、暂停或终止用户使用云平台平台的权利）
              </font>
            </p>
            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp; &nbsp;&nbsp;</span>● 用户违反本协议规定或纳入本协议的相关规定。
              </font>
            </p>
            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp; &nbsp;&nbsp;</span>● 其他用户或第三方通知云平台或投诉用户存在侵权、违法违规、并提供相关证据的，且云平台无法联系到用户或用户无法提供相关证明的。
              </font>
            </p>
            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp; &nbsp;&nbsp;</span>● 其他用户或其他第三方通知云平台，该用户就某个具体事项，存在违法违规或不当行为，云平台可以普通非专业人员的知识水平标准对相关内容进行判别，可以明显认为这些内容或行为可能对其他第三方或云平台造成财务损失或承担法律责任的。
              </font>
            </p>
            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp; &nbsp;&nbsp;</span>● 存在以恶意言论、炒作、发布广告、违反公共利益或可能损坏乱平台正常运行和损坏其他用户利益的行为
              </font>
            </p>
            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp; &nbsp;&nbsp;</span>● 注册资料存在弄虚作假情况。
              </font>
            </p>
            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp;&nbsp;</span>4. 用户承诺不得以任何方式利用云平台平台直接或间接从事违反中国法律以及社会公德的行为，云平台有权对违反上述承诺的内容予以删除，并保留追究其法律责任的权力。
              </font>
            </p>
            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp;&nbsp;</span>5. 云平台依据本协议或纳入本协议的相关规定，云平台有权冻结客户账户。
              </font>
            </p>
            <p>
              <br />
            </p>
          </li>
        </ul>

        <p>

        </p>
        <ul>
          <li style="text-align:left;">
            <p>
              <b>
                <font color="#000000">三、知识产权</font>
              </b>
            </p>

            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp;&nbsp;</span>云平台是一个创造好产品的共享设计平台，我们尊重和鼓励云平台用户创作的内容，认识到保护知识产权对云平台生存与发展的重要性，承诺将保护知识产权作为云平台运营的基本原则之一。
              </font>
            </p>

            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp;&nbsp;</span>1. 由云平台平台在项目中心发起的创意项目中，用户发表的创意内容（包括不仅限于图片、文字、创意及补充说明、创意描述、创意结构），著作权由发表者和云平台共同所有，如需商用，可根据具体的活动规则。
              </font>
            </p>

            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp;&nbsp;</span>2. 云平台平台上的认证设计师在参与设计任务所产生的创意内容，知识产权参照《设计师委托协议》。
              </font>
            </p>

            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp;&nbsp;</span>3. 云平台在上传优秀项目案例时，版权均归用户本人所有或用户本人已经依法获得原始权利人合法授权，任何第三方用户需要转载或用作商业用途，均需与用户联系。
              </font>
            </p>

            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp;&nbsp;</span>4. 云平台及用户在云平台平台所发布展示的【原创作品】【原创文章】等原创内容，版权由作者享有，任何人如需用作商业用途均须联系原作者。临摹作品，同人作品原型版权归原作者所有。原创内容的发布者必须确保其拥有发布内容的全部原创版权，或已经获得版权所有者及相关法律的授权，且不得侵犯他人著作权，肖像权，商标权等合法权利，不侵犯商业秘密。云平台默认用户在云平台平台发布的所有原创类的内容已经获得发表、演绎等版权授权并被授权可在此基础上进行编辑、推荐、推广。若因用户发布的内容产生法律纠纷，全部责任由用户本人自行承担，云平台不承担任何法律责任。
              </font>
            </p>

            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp;&nbsp;</span>5. 第三方若出于非商业目的，将用户在云平台上发表的内容转载在云平台之外的地方，应当在作品的正文开头的显著位置注明原作者姓名（或原作者在云平台上使用的帐号名称），给出原始链接，注明「发表于云平台」，并不得对作品进行修改演绎。若需要对作品进行修改，或用于商业目的，第三方应当联系用户获得单独授权，按照用户规定的方式使用该内容。
              </font>
            </p>

            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp;&nbsp;</span>6. 用户在云平台上参与项目中心的创意内容（包括但不仅限于图片、文字），视为认可平台打赏奖赏规则，获得云平台平台打赏后，其创意内容的知识产权归属云平台所有（包含署名权在内的全部知识产权也均归云平台平台所有），用户表示无异议。
              </font>
            </p>

            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp;&nbsp;</span>7. 用户在云平台上发布的创意内容（包括但不仅限于图片、文字），授予云平台免费的、不可撤销的、非独家使用许可，云平台有权将该内容用于云平台各种形态的产品和服务上，包括但不限于网站（线上平台）以及发表的应用或其他互联网（线上）或线下产品。
              </font>
            </p>

            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp;&nbsp;</span>8. 云平台提供的网络服务中包含的标识、版面设计、排版方式、文本、图片、图形等均受著作权法、商标法等法律保护，未经相关权利人（含云平台及其他原始权利人）同意，上述内容均不得在任何平台被直接或间接发布、使用、出于发布或使用目的的改写或再发行，或被用于其他任何商业目的。
              </font>
            </p>

            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp;&nbsp;</span>9. 云平台用户在云平台上分享并提供下载的【设计资源】（包括素材、软件、工具等），版权均归原作者所有，用户应自行注明“本内容仅供大家学习与参考，非经作者xxx书面授权，请勿使用于商业用途，如需作商业用途，请与原作者联系”或类似提示性文字。
              </font>
            </p>

            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp;&nbsp;</span>10. 云平台上供用户下载学习的设计资源、设计服务文档（包括但不仅限于视频、PDF、文字、图片等）未经云平台书面许可，任何人不可用于商业用途。
              </font>
            </p>

            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp;&nbsp;</span>11. 云平台平台的Logo等文字、图形及其组合，以及云平台平台的其他标识、徵记、产品和服务名称均属云平台及其关联公司，任何人不得以任何方式展示、使用或作其他处理。
              </font>
            </p>

            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp;&nbsp;</span>12. 用户应充分了解并认可，云平台有权但无义务对用户发布的内容进行实质审核，有权根据相关证据结合《侵权责任法》、《信息网络传播权保护条例》等法律法规及云平台社区管理规定对用户发布于云平台平台的涉嫌侵权的信息进行处理，因用户自身原因导致与第三方权属争议的由用户自行负责，与云平台无关。
              </font>
            </p>

            <p>
              <b>
                <font color="#000000">四、隐私权</font>
              </b>
            </p>

            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp;&nbsp;</span>1.
                信息隐私
              </font>
            </p>

            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp; &nbsp;&nbsp;</span>● 尊重用户个人隐私信息的私有性是云平台的一贯原则，云平台将通过技术手段、强化内部管理等办法充分保护用户的个人隐私信息，除法律或有法律赋予权限的政府部门要求或事先得到用户明确授权等原因外，云平台保证不对外公开或向第三方透露用户个人隐私信息，或用户在使用服务时存储的非公开内容。
              </font>
            </p>

            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp; &nbsp;&nbsp;</span>● 用户因认证原因在云平台上传个人资料（包括但不仅限于身份证照片、银行卡号），不论出于任何目的，云平台都不会利用对方的任何保密信息。
              </font>
            </p>

            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp; &nbsp;&nbsp;</span>● 为了运营和改善云平台的技术与服务，云平台将可能会自行收集使用或向第三方提供用户的非个人隐私信息（包括不限于昵称、等级、公开作品），这将有助于云平台向用户提供更好的用户体验和服务质量。
              </font>
            </p>

            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp; &nbsp;&nbsp;</span>● 您使用或继续使用我们的服务，即意味着同意我们按照云平台隐私权收集、使用、储存您的相关信息。
              </font>
            </p>

            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp; &nbsp;&nbsp;</span>● 用户同意其提供的注册资料真实、准确、完整、合法有效，用户个人信息如有变动的，应及时更新个人信息。云平台有权通过征信机构、银行卡绑定等方式，对您提交的身份信息的真实性进行验证。如果用户提供的注册资料不合法、不真实、不准确、不详尽的，用户需承担因此引起的相应法律责任及后果，并且云平台保留终止用户使用云平台各项服务的权利。
              </font>
            </p>

            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp;&nbsp;</span>2.
                信息披露
              </font>
            </p>

            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp;&nbsp;</span>用户的个人信息或企业信息将在下述情况下部分或全部被披露：
              </font>
            </p>

            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp; &nbsp;&nbsp;</span>● 经用户同意，向第三方披露。
              </font>
            </p>

            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp; &nbsp;&nbsp;</span>● 用户在使用云平台平台过程中涉及到知识产权类纠纷，有他方主张权利的，云平台在审核主张方提交的资料后认为披露用户信息有助于纠纷解决的。
              </font>
            </p>

            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp; &nbsp;&nbsp;</span>● 根据法律的有关规定，或者行政、司法机关的要求、政府监管机关命令，向第三方或者行政、司法机关、政府监管机关披露。
              </font>
            </p>

            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp; &nbsp;&nbsp;</span>● 若用户出现违反中国有关法律或者网站规定的情况，需要向第三方披露。
              </font>
            </p>

            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp; &nbsp;&nbsp;</span>● 当用户的人身权和财产权或者第三人的权益面临重大损害时。
              </font>
            </p>

            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp; &nbsp;&nbsp;</span>● 其它云平台平台根据法律或者网站规定认为合适的披露。
              </font>
            </p>

            <p>
              <b>
                <font color="#000000">五、侵权举报及处理</font>
              </b>
            </p>

            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp;&nbsp;</span>1.
                侵权举报
              </font>
            </p>

            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp; &nbsp;&nbsp;</span>● 原则：云平台作为创意讨论社区，高度重视自由表达和个人、企业正当权利的平衡。依照法律规定删除违法违规信息是云平台社区的法定义务。
              </font>
            </p>

            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp; &nbsp;&nbsp;</span>● 受理范围：受理针对用户在云平台平台涉嫌侵犯企业、个人合法权益和公共利益行为的侵权举报，包括但不限于涉及个人隐私、造谣与诽谤等侵权：
              </font>
            </p>

            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>① 涉及个人隐私：发布内容中直接涉及身份信息，如个人姓名、家庭住址、身份证号码、工作单位、私人电话等详细个人隐私；
              </font>
            </p>

            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>② 造谣、诽谤：发布内容中指名道姓（包括自然人和企业）的直接谩骂、侮辱、虚构中伤、恶意诽谤等涉嫌侵犯第三方名誉权、荣誉权的行为；
              </font>
            </p>

            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>③ 其他侵权：包括侵犯第三方知识产权、泄露商业秘密以及侵犯他人肖像权、姓名权等。
              </font>
            </p>

            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp;&nbsp;</span>2.
                举报条件
              </font>
            </p>

            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp; &nbsp;&nbsp;</span>● 权利人对涉嫌侵权内容拥有商标权、著作权、专利权等在先权利的权属证明，如商标注册证、专利证书、著作权登记证+载有创作日期的作品底稿等。
              </font>
            </p>

            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp; &nbsp;&nbsp;</span>● 举报人的身份证明，身份证明可以是身份证或护照。
              </font>
            </p>

            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp; &nbsp;&nbsp;</span>● 如果举报人非权利人，请举报人提供代表权利人进行举报的书面授权证明及利害关系人证明。
              </font>
            </p>

            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp; &nbsp;&nbsp;</span>● 为确保投诉材料的真实性，在侵权举报中，您还需要签署以下法律声明：
              </font>
            </p>

            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>① 我本人为所举报内容的合法权利人或可代表合法权利人；
              </font>
            </p>

            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>② 我举报的发布在云平台平台上的内容侵犯了本人或代表权利人相应的合法权益；
              </font>
            </p>

            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</span>③ 如果本侵权举报内容不完全属实，本人将承担由此产生的一切法律责任，并承担和赔偿云平台因根据投诉人的举报对相关帐号的处理而造成的任何损失，包括但不限于云平台因投诉人举报而处罚错误导致向被投诉方赔偿而产生的损失及云平台名誉、商誉损害等。
              </font>
            </p>

            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp;&nbsp;</span>3.
                处理流程
              </font>
            </p>

            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp; &nbsp;&nbsp;</span>● 出于网络平台的监督属性，并非所有举报申请都必须受理。处理期间，不提供任何电话、邮件及其他方式的查询服务。
              </font>
            </p>

            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp; &nbsp;&nbsp;</span>● 用户知悉并理解：如发生云平台已经删除或处理的内容，但是百度、谷歌等搜索引擎依然可以搜索到的现象，是因为百度、谷歌等搜索引擎自带缓存，此类问题云平台无权也无法处理，因此相关申请不予受理，您可以自行联系搜索引擎服务商进行处理。
              </font>
            </p>

            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp; &nbsp;&nbsp;</span>● 此为云平台平台唯一的官方侵权投诉渠道，暂不提供其他方式处理此业务。
              </font>
            </p>

            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp; &nbsp;&nbsp;</span>● 用户在云平台中的商业行为引发的法律纠纷，由交易双方自行处理，与云平台无关。
              </font>
            </p>

            <p>
              <br />
            </p>
          </li>
        </ul>

        <p>

        </p>
        <ul>
          <li style="text-align:left;">
            <p>
              <b>
                <font color="#000000">六、免责声明</font>
              </b>
              <br />
            </p>

            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp;&nbsp;</span>1. 云平台不能对用户发表的内容或评论的正确性进行保证。
              </font>
            </p>

            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp;&nbsp;</span>2. 用户在云平台发表的内容仅表明其个人的立场和观点，并不代表云平台的立场或观点。作为内容的发表者，需自行对所发表内容负责，因所发表内容引发的一切纠纷，由该内容的发表者承担全部法律责任，云平台不承担任何责任。
              </font>
            </p>

            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp;&nbsp;</span>3. 云平台不保证网络服务一定能满足用户的要求，也不保证网络服务不会中断，对网络服务的及时性、安全性、准确性也都不作完全保证。
              </font>
            </p>

            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp;&nbsp;</span>4. 云平台不担保其云平台服务一定能满足用户的要求。用户理解，云平台会尽其勤勉义务服务于用户，但因客户主观偏好等因素，不能保证客户发布的任务一定能被云平台平台承接；云平台也不能保证客户一定能获得云平台提供的满意的设计方案；云平台也不担保每个设计师都会被选定为服务于客户发布的具体任务的设计师；云平台不担保C端用户一定会被选定为服务于客户发布的具体任务的设计师社区成员。
              </font>
            </p>

            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp;&nbsp;</span>5. 用户理解并同意，受认识的局限性、市场导向变化及销售能力的影响，“小调查”、“疯狂猜”的结果并不一定准确反映产品的市场导向，因客户采用了基于众创任务得出的调研结果所设计产品在上市后未达到客户预期的销售目标的，与云平台无关，云平台不承担任何责任。
              </font>
            </p>

            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp;&nbsp;</span>6. 受认识的局限性影响，用户理解并同意在“画草图”、“集创意”阶段提供的概念创意及草图出自目标用户等参与者，且云平台对该等参与者的行为不可控，如通过“画草图”、“集创意”得出的原始创意图文数据或概念草图设计方案存在抄袭或盗用等侵犯第三方合法权益的行为，与云平台无关，云平台不承担任何责任；因客户采纳了“集创意”、“画草图”的结果进行产品设计而直接或间接影响客户公司声誉或产品销量的，与云平台无关，云平台不承担任何责任。
              </font>
            </p>

            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp;&nbsp;</span>7. 用户理解并同意在 “集创意”环节所产生的吐槽内容并非云平台平台所能控制，针对任何吐槽内容，如侵犯了用户及第三方合法权益的与云平台无关，云平台不承担任何责任。
              </font>
            </p>

            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp;&nbsp;</span>8. 您同意云平台有权将您在参加云平台的线下活动过程中提供的用户名单信息及您现场活动的照片、微信头像使用在其提交给客户的作成果中，云平台平台的客户可将您的上述信息用于其众创产品的用户回访及目标用户储备。
              </font>
            </p>

            <p>
              <b>
                <font color="#000000">七、不可抗力</font>
              </b>
            </p>

            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp;&nbsp;</span>对于因不可抗力包括但不限于战争、水灾、火灾、雷击、台风或地震、罢工、暴动、法定疾病、黑客攻击、计算机网络病毒、电信部门技术管制、或网络运营商商原因、政府行为或任何其它自然或人为造成的灾难等客观云平台不能控制的原因造成的网络服务中断或其它缺陷，云平台不承担任何责任，但将尽力减少因此而给用户造成的损失和影响。
              </font>
            </p>

            <p>
              <b>
                <font color="#000000">八、协议修改</font>
              </b>
            </p>

            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp;&nbsp;</span>1. 根据互联网的发展和有关法律、法规及规范性文件的变化，或者因业务发展需要，云平台有权对本协议的条款作出修改或变更，一旦本协议的内容发生变动，云平台将会直接在云平台网站上（包括不限于：站内公告、页面提示、短信通知）公布修改之后的协议内容，该公布行为视为云平台已经通知用户修改内容。云平台也可采用电子邮件或私信的传送方式，提示用户协议条款的修改、服务变更、或其它重要事项。
              </font>
            </p>

            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp;&nbsp;</span>2. 如果不同意云平台对本协议相关条款所做的修改，用户有权并应当停止使用云平台。如果用户继续使用云平台，则视为用户接受云平台对本协议相关条款所做的修改。
              </font>
            </p>

            <p>
              <font color="#000000">
                <span style="font-size: 0.14rem; color: rgb(102, 102, 102);">&nbsp; &nbsp;&nbsp;</span>3. 除另有约定外，云平台平台及用户的一切行为、争议应依据具体行为、争议发生时最新生效版本的《云平台用户协议》作出并进行解释。
              </font>
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserAgreement",
  data(){
    return{
      info:{
        details:''
      }
    }
  }
}
</script>

<style scoped>
.background{
  width: 19.2rem;
  background-color: #fdf9f8;
  padding-top: .52rem;
}

.breadcrumb div:nth-child(1){
  float: left;
  color: #212121;
}

.breadcrumb div:nth-child(2){
  float: left;
  margin-right: .05rem;
  color: #9c9c9c;
}

.breadcrumb div:nth-child(3){
  float: left;
  margin-right: .05rem;
  color: #9c9c9c;

}

.breadcrumb div:nth-child(4){
  float: left;
  color: #ec414d;
}

.card{
  width: 15.6rem;
  background-color: #ffffff;
  border-radius: .05rem;
  padding-top: .63rem;
  margin: 0 auto .5rem;

}

.card .card_title {
  width: 14.6rem;
  height: .96rem;
  border-bottom: .01rem solid #c5c5c5;
  /*line-height: .96rem;*/
  margin: 0 auto .4rem;
}

.card .card_title .name_lable{
  width: 14.6rem;
  height: .36rem;
  /*background-color: pink;*/
  margin-bottom: .25rem;

}

.card .card_title .name{
  float: left;
  width: 2.52rem;
  height: .36rem;
  color: #212121;
  margin-right: .15rem;
  font-size: .36rem;
  line-height: .36rem;
  /*margin-top: 0;*/
}


.card .card_title .lable{
  float: left;
  height: .36rem;
  /*line-height: .36rem;*/
  text-align: center;
  /*margin: auto 0;*/
}

.card .card_title .lable div{
  float: left;
  width: .94rem;
  height: .3rem;
  background-color: #f8f8f8;
  color: #b3b3b3;
  font-size: .16rem;
  margin-top: .05rem;
  line-height: .3rem;
}

.card .card_title .lable div:nth-child(1){
  /*float: left;*/
  margin-right: .1rem;
}

.card .card_title .popularity{
  float: left;
  width: 14.6rem;
  height: .16rem;
  line-height: .16rem;
  /*background-color: pink;*/
}

.card .card_title .popularity div{
  float: left;
  margin-right: .05rem;
}

.card .content{
  width: 14.6rem;
  padding-left: .8rem;
  margin-bottom: .55rem;
}

.card .content div{
  width: 13.6rem;
  height: 7.65rem;
  margin-bottom: .35rem;
  background-color: pink;
  margin-left: 1rem;
}

.card .end{
  width: 14.6rem;
  height: .4rem;
  margin: 0 auto;
  border-top: .01rem solid #c5c5c5;
  padding-top: .22rem;
  line-height: .4rem;
}

.card .end div{
  float: left;
}

.card .end div:nth-child(1){
  margin-left: .5rem;
  font-size: .16rem;
  color: #a5a5a5;
  margin-right: 5.04rem;
}

.card .end div:nth-child(2){
  height: .21rem;
  font-size: .21rem;
  color: #212121;
  margin-right: .3rem;
}

.card .end div:nth-child(3){
  font-size: .21rem;
  height: .21rem;
  color: #ec414d;
}
</style>